.medicines-header img {
    img {
        height: 100%;
    }
}

.faq-section {
    display: flex !important;
    flex-direction: row-reverse !important;
    gap: 20px;
    margin-top: 20px;

    p {
        min-width: 40%;
    }
}
@media screen and (max-width: 768px) {
    .faq-section {
        flex-direction: column !important;
    }
}
