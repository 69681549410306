
@media screen and (min-width: 768px) {
    .our-service-desktop {
        display: block;
    }

    .our-service-mobile {
        display: none;
    }
}
@media screen and (max-width: 768px) {
    .our-service-desktop {
        display: none;
    }
    
    .our-service-mobile {
        display: block;
    }
}
