#applications input[type='file'] {
    font-size: 12px;
}

#applications input[type='file']::-webkit-file-upload-button {
    padding: 12px;
    font-size: 12px;
    text-align: center;
    width: fit-content;
    margin: 0;
    margin-right: 12px;
    padding: 6px 12px;
}

#applications-select select {
    font-size: 12px;
    padding: 6px 12px;
    margin: 0;
    margin-right: 12px;
}
