.footer-subscribe {
    background: rgb(251, 243, 222);
    background: linear-gradient(
        180deg,
        #fbf3de 0%,
        #dbdadc 9%,
        #b3bbd9 23%,
        #92a2d6 37%,
        #788ed4 52%,
        #6580d3 67%,
        #5b78d2 83%,
        #5775d2 100%
    );
}
