#businesses-tabs {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    button {
        color: #8f9bb3;
        font-size: 12px;
        padding: 10px 20px;
        border-bottom: 3px solid #8f9bb3;
    }

    button[aria-selected='true'] {
        color: #000;
        border-bottom: 3px solid #000;
        font-weight: bold;
    }

    button:hover {
        color: #000;
        border-bottom: 3px solid #000;
        font-weight: bold;
    }
}

@media screen and (max-width: 768px) {
    #businesses-tabs {
        grid-template-columns: 1fr 1fr;
    }
}
