.health-tabs button {
    text-transform: uppercase;
    font-size: 12px;
    border-radius: 0;
    border: none;
}

.health-tabs div[role='tablist'] {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0;
}

.health-tabs button[aria-controls=':r3:-tabpanel-0'],
.health-tabs button[aria-controls=':r1:-tabpanel-0'],
.health-tabs button[aria-controls=':r2:-tabpanel-0'],
.health-tabs button[aria-controls=':r0:-tabpanel-0'] {
    background: #eea880;
    color: #fff;
    text-transform: uppercase;

    &:hover {
        color: #000;
        font-weight: bold;
    }
}

.health-tabs button[aria-controls=':r3:-tabpanel-1'],
.health-tabs button[aria-controls=':r1:-tabpanel-1'],
.health-tabs button[aria-controls=':r2:-tabpanel-1'],
.health-tabs button[aria-controls=':r0:-tabpanel-1'] {
    background: #e98076;
    color: #fff;
    text-transform: uppercase;

    &:hover {
        color: #000;
        font-weight: bold;
    }
}

.health-tabs button[aria-controls=':r3:-tabpanel-2'],
.health-tabs button[aria-controls=':r1:-tabpanel-2'],
.health-tabs button[aria-controls=':r2:-tabpanel-2'],
.health-tabs button[aria-controls=':r0:-tabpanel-2'] {
    background: #5775d3;
    color: #fff;
    text-transform: uppercase;

    &:hover {
        color: #000;
        font-weight: bold;
    }
}

.health-tabs button[aria-selected='true'] {
    color: #000;
    font-weight: bold;
}
