#careers-tabs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 40%;
    padding-left: 0;
    padding-right: 0;
    margin: 0 auto;
    button {
        color: #8f9bb3;
        font-size: 12px;
        padding: 10px 14px;
        border-bottom: 3px solid #8f9bb3;
        text-transform: uppercase;
        white-space: nowrap;
    }

    button[aria-selected='true'] {
        color: #000;
        border-bottom: 3px solid #000;
        font-weight: bold;
    }

    button:hover {
        color: #000;
        border-bottom: 3px solid #000;
        font-weight: bold;
    }
}

.careers {
    &__btn-details {
        background: #edf1f7;
        color: #1f609e;
        font-weight: bold;
        padding: 5px 20px;
        display: inline-block;
        border-radius: 4px;
        font-size: 9pt;
        cursor: pointer;

        &:hover {
            opacity: 0.84;
        }
    }

    &__btn-apply {
        background: #5675d3;
        color: #fff;
        font-weight: bold;
        padding: 5px 20px;
        display: inline-block;
        border-radius: 4px;
        font-size: 9pt;
        cursor: pointer;

        &:hover {
            opacity: 0.84;
        }
    }
}

#file-upload {
    p {
        font-size: 12px;
    }
}

.career-card-modal {
    font-size: 14px !important;

    &__title {
        font-size: 16px !important;
    }
}

@media screen and (max-width: 768px) {
    #careers-tabs {
        width: 100%;
        padding: 0 16px;
    }
}
