/* Package details */

.package-details {
    border: 1px solid #cbd5e0;
    box-shadow: 0px 5px 20px rgb(100 135 159 / 30%);
}

.button-social {
    height: 32px;
    line-height: 32px;
    margin-right: 8px;
    padding: 0 10px;
    cursor: pointer;
    -webkit-transition: opacity 0.2s ease-in, top 0.2s ease-in;
    transition: opacity 0.2s ease-in, top 0.2s ease-in;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
}

.button-social:hover {
    opacity: 0.85;
    margin-top: -4px;
}

#package div[role='dialog'] > div {
    align-items: flex-start;
    height: 100vh;
}

#package div[role='dialog'] > div > div {
    background: none;
    box-shadow: none;
}

#package div[role='dialog'] > div > div > div {
    background: none;
    box-shadow: none;
    width: 60vw;
    border: none;
}

@media screen and (max-width: 768px) {
    #package div[role='dialog'] > div > div > div {
        width: 100vw;
    }
}
