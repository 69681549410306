// ! Pages
@import url(./pages/our-businesses.scss);
@import url('./pages/package.scss');
@import url(./pages/medicines.scss);
@import url('./pages/card-care.scss');
@import url('./pages/careers.scss');
@import url('./pages/our-services.scss');
@import url(./pages/applications.scss);

// ! Layouts
@import url('./layouts/footer.scss');
@import url('./layouts/health-tabs.scss');

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&display=swap');

body {
    box-sizing: border-box;
    font-family: 'Lato';
    font-style: normal;
    color: #555;
}

select {
    // border-radius: 12px !important;
}

.indiana-scroll-container--native-scroll {
    overflow: hidden !important;
}

input[type='radio']:checked {
    color: #5775d3;
}

input[type='radio']:focus {
    outline: none;
    box-shadow: none;
}

.dropdown-header li button {
    text-align: start;
}

.subheader-center {
    background-color: #aa919e;
}

div[role='tabpanel'] {
    padding: 0;
}

#services-card h1,
.lcms-richtext-contents h1 {
    box-sizing: inherit;
    border-width: 0px;
    border-style: solid;
    border-color: rgb(226, 232, 240);
    font-size: 2em;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    font-family: Lato, sans-serif;
    color: black;
    font-weight: bold;
    position: relative;

    &::after {
        content: '';
        background: #5775d3;
        height: 2px;
        width: 60px;
        left: 0;
        bottom: 0;
        position: absolute;
    }
}

.health-tourism__subheader {
    height: 210px !important;
}

.pagination nav {
    display: flex;
}

.pagination-mobile nav {
    li:first-child,
    li:last-child {
        display: none;
    }
}

.pagination nav ul {
    margin-left: auto;
    margin-right: auto;
}

.filter-header select {
    padding-right: 2rem;
    font-size: 14px;
}

.filter-header input {
    min-height: 46px;
}

.event-card {
    background: url('../assets/common/background-event.png');
}

.according button {
    outline: none;
    background: #5775d3;
    color: #fff;
    border: none;
}

.according button:not(:first-child) {
    margin-top: 8px;
}

.according button:hover {
    background: #5775d3;
    opacity: 0.85;
    color: #fff;
}

.according button:focus {
    box-shadow: none;
}

.left-accordion {
    display: none;
}

#events .indiana-scroll-container--hide-scrollbars {
    bottom: 24px;
    position: relative;
}

#contact-us {
    .z-10 {
        z-index: 10;
    }

    .opacity-60 {
        opacity: 0.6;
    }

    .px-12 {
        padding-left: 48px;
        padding-right: 48px;
    }
}

.slick-list {
    height: 100% !important;
}

.slick-next,
.slick-prev {
    width: 40px !important;
    height: 40px !important;
    z-index: 100;

    &:hover {
        opacity: 0.8;
    }

    &::before {
        display: none !important;
    }
}

.slick-dots li button:hover:before {
    opacity: 0.9 !important;
    color: #fff !important;
}

.slick-dots {
    bottom: 25px !important;

    li button {
        padding: 0 !important;

        &::before {
            font-size: 20px !important;
        }
    }
}

.slick-dots li.slick-active button:before {
    color: #fff !important;
}

@media screen and (max-width: 768px) {
    #events {
        margin-top: 28px;
        overflow: hidden;
    }

    #events .indiana-scroll-container--hide-scrollbars {
        bottom: 32px;
        position: relative;
        margin-top: 28px;
    }
}

@media screen and (max-width: 768px) {
    .slick-next {
        right: 20px !important;
    }

    .slick-prev {
        left: 20px !important;
    }

    .slick-dots {
        bottom: 20px !important;
    }
}

.grecaptcha-badge {
    display: none !important;
}
