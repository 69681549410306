#card-care-tabs {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    padding-left: 0;
    padding-right: 0;
    margin: 0 auto;
    button {
        color: #8f9bb3;
        font-size: 12px;
        padding: 10px 14px;
        border-bottom: 3px solid #8f9bb3;
        text-transform: uppercase;
        white-space: nowrap;
    }

    button[aria-selected='true'] {
        color: #000;
        border-bottom: 3px solid #000;
        font-weight: bold;
    }

    button:hover {
        color: #000;
        border-bottom: 3px solid #000;
        font-weight: bold;
    }
}

.card-care-package img {
    object-fit: cover;
    width: 100%;
}

@media screen and (max-width: 768px) {
    #card-care-tabs {
        grid-template-columns: 1fr 1fr;
    }
}
